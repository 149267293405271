<template>
  <div class="svg_holder" :class="[color, size, inline]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" ><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><g><path d="M5,13h11.17l-4.88,4.88c-0.39,0.39-0.39,1.03,0,1.42l0,0c0.39,0.39,1.02,0.39,1.41,0l6.59-6.59 c0.39-0.39,0.39-1.02,0-1.41l-6.58-6.6c-0.39-0.39-1.02-0.39-1.41,0l0,0c-0.39,0.39-0.39,1.02,0,1.41L16.17,11H5 c-0.55,0-1,0.45-1,1l0,0C4,12.55,4.45,13,5,13z"/></g></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconArrowForward',
  props: ['size', 'color', 'inline']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
  .inline {
    display: inline-flex;
  }
</style>
